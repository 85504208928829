// Function to return AWS credentials
const getAWSCredentials = () => {
    return {
        region: "eu-west-1",
        credentials: {
            accessKeyId: "AKIAZI2LD2CGXIIGM5UN",
            secretAccessKey: "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",
        },
    };
};

// Function to return Google Maps API key
const getGoogleMapsApiKey = () => {
    return "AIzaSyBLkXl8YZQGY5zmArCXq_GYlGgyR2OlGa4";
};

// Function to return hardcoded map ID
const getMapId = () => {
    return "ed8e364cc36545a1";
};

// Function to return URLs for AWS services and Google Maps
const getAWSServiceURL = () => {
    return "https://5enp26lhturnnqnozdxg5ofoeq0vykut.lambda-url.eu-west-1.on.aws";
};
// 
const getAPIAWS = () => {
    return "https://bxjdwomca6.execute-api.eu-west-1.amazonaws.com/dev";
};

const getGoogleMapsAPIURL = () => {
    return "https://maps.googleapis.com/maps/api";
};

const getSQSURL = () => {
    return "https://sqs.eu-west-1.amazonaws.com/637423308941/api_queue";
};

module.exports = {
    getAWSCredentials,
    getGoogleMapsApiKey,
    getMapId,
    getAWSServiceURL,
    getGoogleMapsAPIURL,
    getAPIAWS,
    getSQSURL
};
